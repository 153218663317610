import React, { useEffect, useState, useRef } from "react";
import ReactToPrint from "react-to-print";
import { Button } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import { Header } from "./Header";
import { PatientInfo } from "./PatientInfo";
import { TestInfo } from "./TestInfo";
import { TestResults } from "./TestResults";
import { useParams } from "react-router-dom";

const Reports = () => {
  const [results, setesults] = useState([]);
  const componentRef = useRef();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { id } = useParams();
  useEffect(() => {
    fetchTestResults();
  }, []);

  const fetchTestResults = async () => {
    try {
      const response = await fetch(
        `${baseUrl}/list/prometheus/patient-questionare-detail/${id}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch test results");
      }
      const data = await response.json();
      setesults(data?.data?.response?.labResults);
    } catch (error) {
      console.error("Error fetching test results:", error);
    }
  };

  return (
    <div>
      <div ref={componentRef}>
        {results && (
          <div>
            <Header />
            <PatientInfo />
            <TestInfo />
            <TestResults results={results} />
          </div>
        )}
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <ReactToPrint
          trigger={() => (
            <Button
              variant="outlined"
              color="primary"
              startIcon={<PrintIcon />}
            >
              Print Report
            </Button>
          )}
          content={() => componentRef.current}
        />
      </div>
    </div>
  );
};

export default Reports;
