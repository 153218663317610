import { Grid, List, Typography } from "@material-ui/core";
import React from "react";
import lxIcon from "../../Assets/report-logo.png";
import { useNavigate } from "react-router-dom";

export const Header = () => {
  return (
    <>
      <Grid
        style={{ padding: "20px" }}
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        <Grid item xs={6}>
          <List>
            <Typography variant="body1">
              <b>
                PROCESSED AT:
                <br /> LX PREDICTIVE 360
              </b>
            </Typography>
          </List>
          <List>
            <Typography variant="body2">
              D-79, 3rd Floor, sector -63,
              <br /> gautam nagar,
              <br />
              Noida, UP 201301
            </Typography>
          </List>
        </Grid>
        <Grid style={{ textAlign: "end" }} item xs={6}>
          <List>
            <img
              //   style={{ marginRight: '2.5%' }}
              width="102"
              height="99px"
              src={lxIcon}
              alt="LX Icon"
            />
            <br />
            <Typography variant="caption">Test You Can Trust</Typography>
          </List>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <List
          style={{
            backgroundColor: "#BFBFBF",
            clipPath: "polygon(8% 0%, 92% 0%, 90% 100%, 10% 100%)",
            padding: "16px",
            marginBottom: "-10px",
            zIndex: 1000,
          }}
        >
          <Typography variant="caption">
            LX Predective 360 Limited, D-3 Calafornia 4001789 978564524100
            Welness@LXpredective360.com
          </Typography>
        </List>
        <List
          style={{
            backgroundColor: "#0D2E43",
            marginRight: "52px",
            marginLeft: "52px",
          }}
        >
          <Typography
            style={{
              backgroundColor: "#0D2E43",
              color: "white",
              padding: "8px",
              fontStyle: "italic", // Add this line to make the text italic
            }}
            variant="body1"
          >
            <span style={{ color: "#FF9900" }}>9 out of 10 Doctors</span> Trust
            that the P 360 Reports are Accurate & Reliable
          </Typography>
        </List>
      </Grid>
    </>
  );
};
