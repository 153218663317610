import React, { useCallback, useEffect, useState } from "react";
import "./styles.css";
import { Divider, Grid, List, Typography } from "@material-ui/core";
import data from "../../Pages/Reports/dummyData.json";

export const TestResults = React.memo(({ results }) => {
  const [testReport, setTestReport] = useState([]);

  useEffect(() => {
    calculatePercentage();
  }, [results]);

  const calculateGradient = useCallback(item => {
    const gradient = `linear-gradient(90deg, 
      #a52c31 0%, 
      #ffe500 ${item.mediumLowerValue}%, 
      #0c9019 ${item.normalLowerValue}%, 
      #ffe500 ${item.normalUpperValue}%, 
      #a52c31 ${item.mediumUpperValue}%)`;
    return gradient;
  }, []);

  const calculatePercentage = useCallback(() => {
    setTestReport(
      data.map(item => ({
        normalLowerValue: Math.abs(
          (item.normalLowerRange / item.maximumValue) * 100
        ),
        normalUpperValue: Math.abs(
          (item.normalUpperRange / item.maximumValue) * 100
        ),
        mediumLowerValue: Math.abs(
          (item.mediumLowerRange / item.maximumValue) * 100
        ),
        mediumUpperValue: Math.abs(
          (item.mediumUpperRange / item.maximumValue) * 100
        ),
        name: item.name,
        percentage: Math.abs((item.value / item.maximumValue) * 100),
        submittedValue: results.submittedValue,
        value: item.value,
        unit: item.unit,
      }))
    );
  }, [results]);

  return (
    <>
      <Grid container spacing={1} style={{ padding: "20px" }}>
        <Grid item xs={12}>
          <Typography style={{ marginLeft: "2%", marginBottom: "3%" }}>
            <b>Your results</b>
          </Typography>
          {testReport ? testReport.map(item => (
            <List
              className="no-page-break"
              key={item.name}
              style={{ marginLeft: "20px" }}
            >
              <Typography style={{ marginLeft: "3%" }}>{item.name}</Typography>

              <div className="main-container">
                <div
                  className="main-rectangle"
                  style={{
                    background: calculateGradient(item),
                  }}
                >
                  {item.percentage > item.normalLowerValue &&
                  item.percentage < item.normalUpperValue ? (
                    <div
                      className="inner-container"
                      style={{ left: `${item.percentage}%` }}
                    >
                      <span
                        className="ellipse"
                        style={{
                          border: "3px solid #0c9019",
                        }}
                      >
                        <Typography
                          style={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: "800",
                            fontSize: "12px",
                            lineHeight: "123.11%",
                            color: "#000000",
                          }}
                          variant="caption"
                        >
                          <b>
                            {item.value}
                            &nbsp; mg/dl
                          </b>
                        </Typography>
                      </span>
                      <span
                        className="inner-rectangle"
                        style={{ background: "#0c9019" }}
                      >
                        <Typography
                          style={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: "800",
                            fontSize: "12px",
                            lineHeight: "123.11%",
                            color: "#ffffff",
                          }}
                          variant="caption"
                        >
                          Awesome
                        </Typography>
                      </span>
                    </div>
                  ) : (item.percentage >= item.mediumLowerValue &&
                      item.percentage <= item.normalLowerValue) ||
                    (item.percentage >= item.normalUpperValue &&
                      item.percentage <= item.mediumUpperValue) ? (
                    <div
                      className="inner-container"
                      style={{ left: `${item.percentage}%` }}
                    >
                      <span
                        className="ellipse"
                        style={{ border: "3px solid #e1cc11" }}
                      >
                        <Typography
                          style={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: "800",
                            fontSize: "12px",
                            lineHeight: "123.11%",
                            color: "#000000",
                          }}
                          variant="caption"
                        >
                          {item.value}&nbsp; mg/dl
                        </Typography>
                      </span>
                      <span
                        className="inner-rectangle"
                        style={{ background: "#e1cc11" }}
                      >
                        <Typography
                          style={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: "800",
                            fontSize: "12px",
                            lineHeight: "123.11%",
                            color: "#ffffff",
                          }}
                          variant="caption"
                        >
                          Normal
                        </Typography>
                      </span>
                    </div>
                  ) : (
                    <div
                      className="inner-container"
                      style={{ left: `${item.percentage}%` }}
                    >
                      <span className="ellipse">
                        <Typography
                          style={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: "800",
                            fontSize: "12px",
                            lineHeight: "123.11%",
                            color: "#000000",
                          }}
                          variant="caption"
                        >
                          {item.value}&nbsp; mg/dl
                        </Typography>
                      </span>
                      <span className="inner-rectangle">
                        <Typography
                          style={{
                            fontFamily: "Inter",
                            fontStyle: "normal",
                            fontWeight: "800",
                            fontSize: "12px",
                            lineHeight: "123.11%",
                            color: "#ffffff",
                          }}
                          variant="caption"
                        >
                          {item.percentage > item.mediumUpperValue
                            ? "Too High"
                            : "Too Low"}
                        </Typography>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </List>
          ))
          :null
        }
        </Grid>
      </Grid>
      <br />
      <Divider variant="middle" />
      <Divider variant="middle" />
      <br />
    </>
  );
});
