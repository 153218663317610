import { Divider, Grid, List, Typography } from '@material-ui/core';
import React from 'react';

export const TestInfo = () => {
  return (
    <>
      <Divider variant="middle"></Divider>
      <Divider variant="middle"></Divider>

      <Grid container spacing={2} style={{ padding: '20px' }}>
        <Grid item xs={6}>
          <List style={{ marginLeft: '3%' }}>
            <Typography variant="body1">
              <b>About this test</b>
            </Typography>
            <Typography variant="caption">
              This report evalutes your potentional risk of heart disease, heart
              attack, and stroke
            </Typography>
          </List>
        </Grid>
      </Grid>
      <Divider variant="middle"></Divider>
      <Divider variant="middle"></Divider>
    </>
  );
};
