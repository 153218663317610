import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  Grid,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  LinearProgress,
  IconButton,
  SnackbarContent,
  Typography,
  Snackbar,
  List,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { useParams, useNavigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "./styles.css";
import { format } from "date-fns";
import { Alert, Stack } from "@mui/material";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  inputContainer: {
    display: "Grid",
    padding: theme.spacing(2),
    backgroundColor: "white",
    borderRadius: "4px",
    boxShadow: "0 2px 4px rgb(205 205 205)",
  },
  label: {
    marginBottom: theme.spacing(1),
  },
  inputFieldContainer: {
    display: "flex",
    alignItems: "center",
    width: "98%",
  },
  radioLabel: {
    fontFamily: "Urbanist",
    fontWeight: "normal",
  },
  checkboxLabel: {
    fontFamily: "Urbanist",
    fontWeight: "normal",
  },
  textField: {
    "& input": {
      fontFamily: "Urbanist",
      fontWeight: "normal",
    },
  },
  BlueText: {
    color: "blue",
  },
}));

const QuestionsForm = () => {
  const classes = useStyles();
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState([]);
  const [answers, setAnswers] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [submissionResults, setSubmissionResults] = useState({});
  const [questionnaireId, setQuestionnaireId] = useState("");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const history = useNavigate();
  const [initialLoad, setInitialLoad] = useState(true);
  const [updatedFields, setUpdatedFields] = useState({});
  const [mark, setMark] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const { id } = useParams();

  const handlePrevious = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNext = () => {
    window.scrollTo(0, 0);

    const pageQuestions = data.filter(
      question => question.page === String(currentPage)
    );
    const newErrors = {};

    pageQuestions.forEach(question => {
      if (question.required && !answers[question.id]) {
        newErrors[question.id] = "This field is required";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
      setCurrentPage(currentPage + 1);
    }
  };

  const handleFinish = () => {
    history("/thank-you");
  };

  const handleAnswerChange = (event, id) => {
    const { type, value, checked } = event.target;
    setAnswers(prevAnswers => {
      if (type === "checkbox") {
        const currentAnswers = Array.isArray(prevAnswers[id])
          ? prevAnswers[id]
          : [];
        return {
          ...prevAnswers,
          [id]: checked
            ? [...currentAnswers, value]
            : currentAnswers.filter(option => option !== value),
        };
      } else {
        return {
          ...prevAnswers,
          [id]: value,
        };
      }
    });
    setUpdatedFields(prevUpdatedFields => ({
      ...prevUpdatedFields,
      [id]: true,
    }));
    if (type == "radio" || type == "checkbox") {
      handleBlur(event, id);
    }
  };

  const handleCheckboxChange = (event, id) => {
    const { value, checked } = event.target;
    setAnswers(prevAnswers => {
      const currentAnswers = Array.isArray(prevAnswers[id])
        ? prevAnswers[id]
        : [];
      const newAnswers = checked
        ? [...currentAnswers, value]
        : currentAnswers.filter(option => option !== value);
      return {
        ...prevAnswers,
        [id]: newAnswers,
      };
    });
    handleBlur(event, id);
  };

  const handleBlur = async (event, id) => {
    const { type, value, checked } = event.target;
    let submittedValue = value;
    if (type === "checkbox") {
      const currentAnswers = Array.isArray(answers[id]) ? answers[id] : [];
      submittedValue = checked
        ? [...currentAnswers, value]
        : currentAnswers.filter(option => option !== value);
    }

    if (type === "text" && submittedValue.trim() === "") {
      console.log("Text input field is empty, API call not made");
      return;
    }
    try {
      const response = await fetch(`${baseUrl}/list/prometheus/submit`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          questionnaireId,
          questionId: id,
          submittedValue,
        }),
      });

      const data = await response.json();

      setSubmissionResults(prevResults => ({
        ...prevResults,
        [id]: data.success,
      }));
    } catch (error) {
      console.error("Error submitting answer:", error);
      setSubmissionResults(prevResults => ({
        ...prevResults,
        [id]: false,
      }));
    }
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${baseUrl}/list/prometheus/patient-questionare-detail/${id}`
      );
      const data = await response.json();
      setMark(data?.data?.response);
      const questions = data?.data?.response.questionare || [];
      setData(questions);
      setQuestionnaireId(data?.data?.response?.id);
      const initialAnswers = questions.reduce((acc, question) => {
        acc[question.id] =
          question.submittedValue ||
          (question.inputType === "checkbox" ? [] : "");
        return acc;
      }, {});
      setAnswers(initialAnswers);
      setInitialLoad(false);
    } catch (error) {
      setError("An error occurred while fetching data.");
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const questionsForCurrentPage = data.filter(
    question => question.page === String(currentPage)
  );

  const totalQuestions = data.length;
  const answeredQuestions = Object.values(answers)
    .flat()
    .filter(
      answer =>
        answer !== "" && (Array.isArray(answer) ? answer.length > 0 : true)
    ).length;
  const progress = (answeredQuestions / totalQuestions) * 100;

  const totalPages = Math.max(...data.map(q => q.page));

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {mark?.isCompleted ? (
        <Stack
          sx={{ position: "fixed", width: "100%", top: 0, zIndex: 1000 }}
          spacing={2}
        >
          <Alert
            severity="error"
            icon={false}
            sx={{
              textAlign: "center",
              "& .MuiAlert-message": {
                width: "100%",
              },
            }}
          >
            Questionnaire Form Marked As Complete By Admin
          </Alert>
        </Stack>
      ) : null}
      <LinearProgress
        style={{
          height: "15px",
          width: "100%",
          marginTop: "-8px",
          position: "fixed",
        }}
        variant="determinate"
        value={progress}
      />
      <Grid container className={classes.root} justifyContent="center">
        <Grid item xs={12} sm={10} md={8} lg={6}>
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            style={{ marginTop: mark?.isCompleted ? "30px" : null }}
            spacing={2}
          >
            <List style={{ marginLeft: "2%" }}>
              <Typography style={{ fontFamily: "Urbanist" }} variant="h5">
                <b>LX Predictive 360</b>
              </Typography>
              <Typography
                style={{ color: "", fontFamily: "Urbanist" }}
                variant="subtitle2"
              >
                <b>Guides user through a health questionnaire</b>
              </Typography>
            </List>
            <List style={{ marginLeft: "2%" }}>
              <Typography
                style={{
                  fontFamily: "Urbanist",
                  color: "rgba(220, 38, 38, 1)",
                }}
                variant="subtitle2"
              >
                <b>* Indicates required question</b>
              </Typography>
              <Typography
                style={{
                  fontFamily: "Urbanist",
                  color: "rgb(5 150 105)",
                }}
                variant="subtitle2"
              >
                <CheckCircleIcon
                  style={{
                    marginTop: "7px",
                    fontSize: "medium",
                    fontFamily: "Urbanist",
                    color: "rgb(5 150 105)",
                    alignItems: "center",
                    alignContent: "center",
                    justifyContent: "center",
                    marginBottom: "-6px",
                  }}
                />{" "}
                <b>Responses are autosaved, submission is not required</b>
              </Typography>
            </List>

            {questionsForCurrentPage.map(question => (
              <Grid item xs={12} key={question.id} style={{ width: "100%" }}>
                <div className={classes.inputContainer}>
                  <label
                    style={{ fontFamily: "Urbanist" }}
                    className={classes.label}
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {question.question}
                      {question.required && (
                        <Typography style={{ marginLeft: "5px", color: "red" }}>
                          *
                        </Typography>
                      )}
                    </div>
                  </label>
                  <div className={classes.inputFieldContainer}>
                    {question.inputType === "select" ? (
                      <TextField
                        select
                        value={answers[question.id] || ""}
                        onChange={event =>
                          handleAnswerChange(event, question.id)
                        }
                        variant="outlined"
                        fullWidth
                        error={!!errors[question.id]}
                        helperText={errors[question.id]}
                      >
                        {question.options.split(",").map(option => (
                          <MenuItem
                            disabled={mark?.isCompleted}
                            style={{
                              fontFamily: "Urbanist",
                              fontWeight: "normal",
                            }}
                            key={option}
                            value={option}
                          >
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : question.inputType === "checkbox" ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "center",
                          }}
                        >
                          {question.options.split(",").map(option => (
                            <FormControlLabel
                              key={option}
                              control={
                                <Checkbox
                                  disabled={mark?.isCompleted}
                                  checked={
                                    Array.isArray(answers[question.id]) &&
                                    answers[question.id].includes(option)
                                  }
                                  onChange={event =>
                                    handleCheckboxChange(event, question.id)
                                  }
                                  value={option}
                                />
                              }
                              label={
                                <span className={classes.checkboxLabel}>
                                  {option}
                                </span>
                              }
                            />
                          ))}
                        </div>
                      </>
                    ) : question.inputType === "radio" ? (
                      <RadioGroup
                        value={answers[question.id] || ""}
                        onChange={event =>
                          handleAnswerChange(event, question.id)
                        }
                        style={{ flexDirection: "row" }}
                      >
                        {question.options.split(",").map(option => (
                          <FormControlLabel
                            disabled={mark?.isCompleted}
                            key={option}
                            control={<Radio />}
                            label={
                              <span className={classes.radioLabel}>
                                {option}
                              </span>
                            }
                            value={option}
                          />
                        ))}
                      </RadioGroup>
                    ) : question.inputType === "date" ? (
                      <DatePicker
                        inputProps={{ disabled: mark?.isCompleted }}
                        label="MM/DD/YYYY"
                        value={
                          answers[question.id]
                            ? new Date(answers[question.id])
                            : null
                        }
                        onChange={date => {
                          if (date) {
                            const formattedDate = format(date, "MM/dd/yyyy");
                            handleAnswerChange(
                              { target: { value: formattedDate } },
                              question.id
                            );
                            handleBlur(
                              { target: { value: formattedDate } },
                              question.id
                            );
                          } else {
                            handleAnswerChange(
                              { target: { value: null } },
                              question.id
                            );
                            handleBlur(
                              { target: { value: null } },
                              question.id
                            );
                          }
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            fullWidth
                            error={!!errors[question.id]}
                            helperText={errors[question.id]}
                            className={classes.datePicker}
                          />
                        )}
                        disabled={mark?.isCompleted}
                      />
                    ) : (
                      <TextField
                        value={answers[question.id] || ""}
                        autocomplete="off"
                        onChange={event =>
                          handleAnswerChange(event, question.id)
                        }
                        className={classes.textField}
                        onBlur={event => handleBlur(event, question.id)}
                        variant="outlined"
                        fullWidth
                        type={question.inputType}
                        error={!!errors[question.id]}
                        helperText={errors[question.id]}
                        InputProps={{
                          disabled: mark?.isCompleted,
                          className: `${
                            initialLoad && answers[question.id] !== ""
                              ? classes.BlueText
                              : updatedFields[question.id]
                              ? ""
                              : classes.BlueText
                          }`,
                        }}
                      />
                    )}
                    {submissionResults[question.id] !== undefined && (
                      <span style={{ display: "flex", flexDirection: "end" }}>
                        {submissionResults[question.id] != false ? (
                          <CheckCircleIcon
                            style={{ color: "green", marginRight: "-50" }}
                          />
                        ) : (
                          <CancelIcon
                            style={{ color: "red", marginRight: "-50" }}
                          />
                        )}
                      </span>
                    )}
                  </div>
                </div>
              </Grid>
            ))}
            <Grid item xs={12} container justifyContent="space-between">
              <Button
                variant="contained"
                color="primary"
                disabled={currentPage === 0}
                onClick={handlePrevious}
              >
                Previous
              </Button>
              {currentPage === totalPages ? (
                <Button
                  disabled={mark?.isCompleted}
                  variant="contained"
                  color="primary"
                  onClick={handleFinish}
                >
                  Finish
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  disabled={currentPage === totalPages}
                >
                  Next
                </Button>
              )}
            </Grid>
            <Snackbar
              open={!!error}
              autoHideDuration={6000}
              onClose={() => setError(null)}
            >
              <SnackbarContent
                style={{ backgroundColor: "#f44336" }}
                message={error}
                action={
                  <IconButton
                    size="small"
                    color="inherit"
                    onClick={() => setError(null)}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                }
              />
            </Snackbar>
          </Grid>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

export default QuestionsForm;
