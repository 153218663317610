import { Grid, List, Typography } from '@material-ui/core';
import React from 'react';

export const PatientInfo = () => {
  return (
    <>
      <Grid container spacing={2} style={{ padding: '20px' }}>
        <Grid item xs={6}>
          <List>
            <Typography variant="body1">
              <b>NAME</b>: KATHERINE LANGFORD 26Y/F
            </Typography>
          </List>
          <List>
            <Typography variant="body1">
              <b>REF. BY</b>: SELF
            </Typography>
          </List>
          <List>
            <Typography variant="body1">
              <b>TEST ASKED</b>: AAROGYAM C
            </Typography>
          </List>
          <List>
            <Typography variant="body1">
              <b>PATEENTID</b>: KL22374102
            </Typography>
          </List>
        </Grid>

        <Grid
          item
          xs={6}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            textAlign: 'left',
          }}
        >
          <List>
            <Typography variant="body1">HOME COLLECTION:</Typography>
            <Typography variant="caption">
              B-85 B4 PANCHSEEL VIHAR SHEIKH SARAI <br /> MALVIYA NAHAR 110017
              LAT: 28 5334659 LONG: <br /> 77 21698875858774474747 PINCODE:
              110017 INDIA
            </Typography>
          </List>
        </Grid>
      </Grid>
    </>
  );
};
