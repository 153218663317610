import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import QuestionsForm from "./Pages/Questionare/QuestionsForm";
import Reports from "./Pages/Reports/Reports";
import { ThankYou } from "./Pages/Questionare/thankYou";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/report/:id" element={<Reports />} />
        <Route path="/questionnaire/:id" element={<QuestionsForm />} />
        <Route path="/thank-you/" element={<ThankYou />} />
      </Routes>
    </Router>
  );
}

export default App;
