import React from "react";
import "./styles.css";
import Lottie from "lottie-react";
import checkMarkAnimation from "./checkMark.json";

export const ThankYou = () => {
  return (
    <div className="thank-you-container">
      <Lottie
        className="check-mark-animation"
        animationData={checkMarkAnimation}
        loop={false}
      />

      <h2 style={{ fontFamily: "Urbanist", textAlign: "center" }}>
        Thank you for completing your Predictive 360 Questionnaire
      </h2>
    </div>
  );
};
